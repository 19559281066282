<template>
  <h2>1. Descripción del juego</h2>
  <p>
    El juego de baloncesto de la NBA de Highlight Games “NBA ÚLTIMOS 60” es una simulación única de cortometrajes de
    partidos históricos seleccionados aleatoriamente por una computadora para crear oportunidades de apuesta. 
  </p>
  <h2>2. Descripción del juego y duración</h2>
  <p>
    “NBA ÚLTIMOS 60” contiene una serie de páginas que permitirán al jugador informarse de los equipos que serán
    protagonistas de las últimas jugadas de un partido de baloncesto. Las páginas que componen el juego presentarán los
    mercados de apuesta disponible, el partido y los cortometrajes de jugadas seleccionadas aleatoriamente y el
    resultado final. Cada partido dura un total aproximado de 3 minutos que van desde el preámbulo inicial hasta el
    resultado final del partido.
  </p>
  <p>
    La selección de eventos de baloncesto de la NBA se lleva acabo mediante un generador de números aleatorios (GNA o
    RNG por sus siglas en inglés) por lo cual es estadísticamente independiente, aleatoria e impredecible.
  </p>
  <h2>3. Mercados de apuesta</h2>
  <h3>3.1 Ganador General</h3>
  <p>
    Un jugador podrá apostar al ganador general del Partido basado tanto en el marcador general como en el de los
    últimos momentos del partido. El marcador general nunca será un empate en los “ÚLTIMOS 60”.
  </p>
  <h3>3.2 Ganador de los ÚLTIMOS 60</h3>
  <p>
    Apuesta al equipo ganador en el marcador de las últimas jugadas del partido solamente. Este mercado nunca será un
    empate.   
  </p>
  <h3>3.3 Ambos equipos anotan más de 2.5 puntos en los ÚLTIMOS 60</h3>
  <p>Un jugador podrá apostar en ambos equipos local y visitante que anoten más de 2.5 puntos cada uno.</p>
  <h3>3.4 Ambos equipos anotan más de 3.5 puntos en los ÚLTIMOS 60</h3>
  <p>Un jugador podrá apostar en ambos equipos local y visitante que anoten más de 3.5 puntos cada uno.</p>
  <h3>3.5 Total 3-Puntos en los ÚLTIMOS 60</h3>
  <p>
    Apuesta a la cantidad de 3-puntos anotados por ambos equipos durante las últimas jugadas del Partido. La máxima
    cantidad de 3-puntos que se pueden anotar en un juego es de 6.
  </p>
  <h3>3.6 Primer equipo en anotar</h3>
  <p>Apuesta en la que el primer equipo local o visitante anota.</p>
  <h3>3.7 Diferencia de pts en los ÚLTIMOS 60</h3>
  <p>
    Apuesta sobre el margen de puntos entre el equipo ganador y el equipo perdedor. Ejemplo: 1-3, 4-6, 7-9. 10-12 o más
    de 12 puntos de diferencia entre ambos equipos en “ÚLTIMOS 60”.
  </p>
  <h3>3.8 Primero en anotar 4 pts</h3>
  <p>Apuesta en la que el primer equipo, local o visitante, anota 4 puntos en “ÚLTIMOS 60”. </p>
  <h3>3.9 Equipo Local Más/Menos de 4.5 pts</h3>
  <p>Apuesta en la que los puntos anotados por el equipo local en “ÚLTIMOS 60” serán más o menos de 4.5.</p>
  <h3>3.10 Equipo Visitante Más/Menos de 4.5 pts</h3>
  <p>Apuesta en la que los puntos anotados por el equipo visitante en “ÚLTIMOS 60” serán más o menos de 4.5.</p>
  <h3>3.11 Equipo Local Más/Menos de 5.5 pts</h3>
  <p>Apuesta en la que los puntos anotados por el equipo local en “ÚLTIMOS 60” serán más o menos de 5.5.</p>
  <h3>3.12 Equipo Visitante Más/Menos de 5.5 pts</h3>
  <p>Apuesta en la que los puntos anotados por el equipo visitante en “ÚLTIMOS 60” serán más o menos de 5.5.</p>
  <h3>3.13 Ganador de los “ÚLTIMOS 60” y Ma/Me 11.5</h3>
  <p>
    Apuesta en la que se predice el ganador del juego “ÚLTIMOS 60” y si hay más o menos de 11.5 puntos anotados entre
    ambos equipos.
  </p>
  <h3>3.14 Ganador de los “ÚLTIMOS 60” y margen de puntos</h3>
  <p>
    Apuesta en la que se predice el ganador del juego “ÚLTIMOS 60” así como el margen de dicha Victoria. Por cuantos
    puntos ganará el equipo. 
  </p>
</template>
